import styled from "styled-components";
import Typography from "../../components/Typography";
import { Input, Button } from "../../components/form/";
import { useMemo, useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/Global";
import { FaCrown } from "react-icons/fa";
import { validateCardNumber, validateCardDate } from "../../utils/creditCard";
import { pay } from "../../services/api";
import Loading from "../../components/Loading";

const CreditCard = () => {
  const { changeStep } = useGlobalContext();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [exp_date, setExp_date] = useState("");
  const [security_code, setSecurityCode] = useState("");
  const [loading, setLoading] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const [month, year] = exp_date.split("/");

    pay("credit-card", {
      holder_name: name,
      number: number.replace(/\D/g, ""),
      exp_month: Number(month),
      exp_year: Number(year),
      cvv: security_code,
      brand: "Mastercard",
    })
      .then(() => {
        changeStep("creditcardsuccess");
      })
      .catch((err) =>
        alert(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: "/credit-card",
        title: "Cartão de Crédito",
        step: "step-2-6",
      },
    });
  }, []);

  const errors = useMemo(
    () => ({
      number:
        !number || validateCardNumber(number.replace(/\D/g, ""))
          ? ""
          : "Insira um cartão válido",
      security_code:
        !security_code || security_code.length === 3
          ? ""
          : "Insira um CVV válido",
      exp_date:
        !exp_date || validateCardDate(exp_date) ? "" : "Insira uma data válida",
    }),
    [number, security_code, exp_date]
  );

  const disableButton = useMemo(
    () =>
      !name ||
      !number ||
      errors.number ||
      !exp_date ||
      errors.exp_date ||
      !security_code ||
      errors.security_code,
    [name, number, exp_date, security_code, errors]
  );

  return (
    <Container>
      <Loading
        isloading={loading}
        text="Estamos processando seu pagamento..."
      />
      <Typography title>Cartão de Crédito</Typography>
      <Typography>Preencha com os dados do cartão</Typography>
      <PlanInfo>
        <FaCrown size={24} color="#fdc40f" />
        <Typography>
          Plano PRO <strong>R$ 389,00/mês</strong>
        </Typography>
      </PlanInfo>

      <Form onSubmit={handleSubmit}>
        <div>
          <StyledInput
            label="Número do cartão"
            value={number}
            mask="9999 9999 9999 99999"
            onChange={(e) => setNumber(e.target.value)}
            placeholder="Digite o número impresso no cartão"
            error={errors.number}
            required
          />
          <StyledInput
            label="Nome no cartão"
            value={name}
            onChange={(e) => setName(e.target.value.toUpperCase())}
            placeholder="Digite o nome impresso no cartão"
            required
          />
          <InputRow>
            <StyledInput
              label="Data de validade"
              value={exp_date}
              mask="99/99"
              onChange={(e) => setExp_date(e.target.value)}
              placeholder="Mês/Ano"
              error={errors.exp_date}
              required
            />
            <StyledInput
              label="CVV"
              value={security_code}
              mask="999"
              onChange={(e) => setSecurityCode(e.target.value)}
              placeholder="123"
              error={errors.security_code}
              required
            />
          </InputRow>
        </div>
        <Button disabled={disableButton}>Concluir Pagamento</Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;

  & > p {
    margin-bottom: 8px;
  }

  & > p {
  }
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 24px;

  svg {
    margin-right: 0.5rem;
  }

  strong {
    color: #292e32;
    font-weight: 500;
  }
`;

const Form = styled.form`
  flex: 1;
  padding-top: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0.5rem;
`;

const InputRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  width: 100%;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    flex: 1;
  }
`;

export default CreditCard;
