import styled from "styled-components";
import Typography from "../../components/Typography";
import { Input, Button, Checkbox } from "../../components/form/";
import { FiArrowRight } from "react-icons/fi";
import { useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "../../contexts/Global";
import { sendData } from "../../services/api";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import Loading from "../../components/Loading";

const ClientData = () => {
  const { setTrackPageView } = useTrackingCode();
  const { data, changeStep, changeData } = useGlobalContext();
  const [name, setName] = useState("");
  const [cpf_cnpj, setCpf_cnpj] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [accept_contact, setAccept_contact] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(data.name || "");
    setCpf_cnpj(data.cpf_cnpj || "");
    setEmail(data.email || "");
    setPhone(data.phone || "");
    setAccept_contact(data.accept_contact || true);
  }, [data]);

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: "/client-data",
        title: "Fale um pouco mais sobre você",
        step: "step-2-1",
      },
    });
  }, []);

  useEffect(() => {
    setTrackPageView();
  }, [setTrackPageView]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    changeData({ name, cpf_cnpj, email, phone, accept_contact });
    sendData("first", {
      full_name: name,
      document: cpf_cnpj.replace(/\D/g, ""),
      cellphone: phone,
      whatsapp_accept: accept_contact,
      email,
    })
      .then(() => changeStep("clientaddress"))
      .catch((err) =>
        alert(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  const cpfCnpjMask = useMemo(
    () =>
      cpf_cnpj.replace(/\D/g, "").length < 12
        ? "999.999.999-999"
        : "99.999.999/9999-99",
    [cpf_cnpj]
  );

  const phoneMask = useMemo(
    () =>
      phone.replace(/\D/g, "").length < 11
        ? "(99) 9999-99999"
        : "(99) 99999-9999",
    [phone]
  );

  const errors = useMemo(() => {
    return {
      cpf_cnpj:
        !cpf_cnpj ||
        /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/.test(
          cpf_cnpj
        )
          ? ""
          : "Insira um documento válido",
      email:
        !email || /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
          ? ""
          : "Insira um e-mail válido",
      phone:
        !phone || /\d{10,11}/.test(phone.replace(/\D/g, ""))
          ? ""
          : "Insira um número válido",
    };
  }, [cpf_cnpj, email, phone]);

  const disableButton = useMemo(
    () =>
      !name ||
      !cpf_cnpj ||
      errors.cpf_cnpj ||
      !email ||
      errors.email ||
      !phone ||
      errors.phone,
    [cpf_cnpj, name, email, phone, errors]
  );

  return (
    <Container>
      <Loading isloading={loading} text="Um momento..." />
      <Typography title>Fale um pouco mais sobre você</Typography>
      <Form onSubmit={handleSubmit}>
        <div>
          <StyledInput
            label="Nome completo"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Ex: Breno da Silva"
            required
          />
          <StyledInput
            label="CPF/CNPJ"
            value={cpf_cnpj}
            mask={cpfCnpjMask}
            onChange={(e) => setCpf_cnpj(e.target.value)}
            placeholder="Ex: XX. XXX. XXX/0001-XX."
            error={errors.cpf_cnpj}
            required
          />
          <StyledInput
            label="E-mail"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ex: brenodasilva@gmail.com"
            error={errors.email}
            required
          />
          <StyledInput
            label="Telefone"
            value={phone}
            mask={phoneMask}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Ex: (00) 00000-0000"
            error={errors.phone}
            required
          />
          <Checkbox
            text="Aceito receber contato pelo WhatsApp"
            checked={accept_contact}
            onChange={() => setAccept_contact(!accept_contact)}
          />
        </div>
        <Button disabled={disableButton} icon={FiArrowRight}>
          Próximo
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0.5rem;
`;

export default ClientData;
