import styled from "styled-components";
import Typography from "../../components/Typography";
import { Input, Button } from "../../components/form/";
import { FiArrowRight } from "react-icons/fi";
import { useMemo, useState, useEffect } from "react";
import { useGlobalContext } from "../../contexts/Global";
import { sendData } from "../../services/api";
import Loading from "../../components/Loading";

const ClientPassword = () => {
  const { changeStep } = useGlobalContext();
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: "/client-password",
        title: "Quase lá! Crie sua senha e acesse sua conta",
        step: "step-2-3",
      },
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    sendData("third", {
      password,
      confirm_password,
    })
      .then(() => changeStep("newclientplan"))
      .catch((err) =>
        alert(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  const errors = useMemo(
    () => ({
      password:
        !!password && password.length < 6 ? "A senha é muito curta" : null,
      confirm_password:
        !!confirm_password && confirm_password !== password
          ? "As senhas não coincidem"
          : null,
    }),
    [confirm_password, password]
  );

  const disableButton = useMemo(
    () => !password || !confirm_password || password !== confirm_password,
    [password, confirm_password]
  );

  return (
    <Container>
      <Loading isloading={loading} text="Um momento..." />
      <Typography title>Quase lá! Crie sua senha e acesse sua conta</Typography>
      <Form onSubmit={handleSubmit}>
        <div>
          <Input
            type="password"
            label="Criar senha"
            error={errors.password}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua nova senha aqui"
            required
          />
          <Input
            type="password"
            label="Confirmar senha"
            value={confirm_password}
            onChange={(e) => setConfirm_password(e.target.value)}
            placeholder="Confirme a nova senha aqui"
            error={errors.confirm_password}
            required
          />
        </div>
        <Button disabled={disableButton} icon={FiArrowRight}>
          Próximo
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export default ClientPassword;
