import styled from "styled-components";
import Typography from "../../components/Typography";
import { BsCheckCircleFill } from "react-icons/bs";
import { useEffect } from "react";

const AlreadyClientSuccess = () => {
  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: "/already-client-success",
        title: "Sua solicitação foi enviada com sucesso!",
        step: "step-1-3",
      },
    });
  }, []);

  return (
    <Container>
      <Content>
        <BsCheckCircleFill size={64} color="#4ec153" />
        <Typography title>Sua solicitação foi enviada com sucesso!</Typography>
        <Typography>
          Em breve, nosso time de relacionamento vai entrar em contato com você.
        </Typography>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 24px 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  svg {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 8px;
  }
`;

export default AlreadyClientSuccess;
