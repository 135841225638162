import GlobalStyle from "./assets/GlobalStyle";
import StepManager from "./components/StepManager";
import { GlobalProvider } from "./contexts/Global";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WMZNTFT",
};
TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <GlobalProvider>
      <GlobalStyle />
      <StepManager />
    </GlobalProvider>
  );
}

export default App;
