import styled from "styled-components";
import Typography from "../../components/Typography";
import { BsCheckCircleFill } from "react-icons/bs";
import { Button } from "../../components/form";
import { useEffect } from "react";

const AlreadyClientSuccess = () => {
  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: "/credit-card-success",
        title: "Pronto. Tudo certo!",
        step: "step-2-7",
      },
    });
  }, []);

  const openApp = () => window.open("https://app.yooga.com.br", "_blank");

  return (
    <Container>
      <Content>
        <BsCheckCircleFill size={64} color="#4ec153" />
        <Typography title>Pronto. Tudo certo!</Typography>
        <Typography>
          Agora você tem uma gestão suave! Em breve, nosso time vai entrar em
          contato com você pelo WhatsApp.
        </Typography>
      </Content>
      <Button onClick={openApp}>Entrar no sistema</Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  svg {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 8px;
  }
`;

export default AlreadyClientSuccess;
